<template>
  <v-flex xs12 style="margin: 10px 0 5px 0; background-color: #cccccc80;padding: 10px 5px;">
    <v-layout wrap>
      <v-flex xs4>
    <v-text-field
      v-model="value"
      outlined
      label="Nombre plato"
      dense
      hide-details
    />
      </v-flex>
      <v-flex xs4 style="padding-left:10px;">
        <v-text-field
          v-model="color"
          outlined
          label="Color en formulario"
          append-outer-icon="mdi-delete"
          dense
          hide-details
          @click:append-outer="onDelete(editedItem)"
        />
      </v-flex>
      <v-flex xs4 style="padding-left:10px;">
        <v-text-field
          v-model="colorContent"
          outlined
          label="Color plato"
          dense
          hide-details
        />
      </v-flex>
    </v-layout>
      <v-toolbar dense elevation="0" style="margin-top: 10px;">
        <v-toolbar-title>Opciones <small style="color: #CCCCCC;">({{value}})</small></v-toolbar-title>
        <v-spacer/>
        <v-btn
          @click="handleAddOption"
          color="#cccccc"
        >
          {{ $t('common.add', locale) }}
        </v-btn>
      </v-toolbar>
      <v-layout wrap style="background-color: #CCCCCC; padding: 0 5px;">
        <option-item
          v-for="option of optionConfig"
          :key="option.id"
          :editedItem="option"
          :onChange="handleChangeOption"
          :onDelete="handleDeleteOption"
        />
      </v-layout>
  </v-flex>
</template>
<script>
import utils from '@/services/utils'
import OptionItem from './OptionItem'
export default {
  name: 'RowItem',
  components: {
    OptionItem,
  },
  props: {
    editedItem: {
      type: Object,
      required: true
    },
    onChange: {
      type: Function,
      required: true,
    },
    onDelete: {
      type: Function,
      required: true,
    },
    locale: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    value: null,
    color: null,
    colorContent: null,
    optionConfig: [],
  }),
  watch: {
    value () {
      this.handleChange()
    },
    color () {
      this.handleChange()
    },
    colorContent () {
      this.handleChange()
    },
  },
  mounted () {
    this.prepareValue()
  },
  methods: {
    prepareValue () {
      this.value = this.editedItem.name
      this.color = this.editedItem.color
      this.colorContent = this.editedItem.colorContent
      if (this.editedItem.options) this.optionConfig = JSON.parse(JSON.stringify(this.editedItem.options))
    },
    handleChange () {
      this.onChange({
        id: this.editedItem.id,
        name: this.value,
        color: this.color && this.color.length > 0 ? this.color : null,
        colorContent: this.colorContent && this.colorContent.length > 0 ? this.colorContent : null,
        options: this.optionConfig
      })
    },

    handleAddOption () {
      this.optionConfig.push({
        id: utils.getNewID(),
        name: `Opci�n ${this.optionConfig.length + 1}`,
      })
      this.handleChange()
    },
    handleChangeOption (v) {
      const foundIndex = this.optionConfig.findIndex(x => x.id === v.id)
      this.optionConfig[foundIndex] = v
      this.handleChange()
    },
    handleDeleteOption (v) {
      this.optionConfig = this.optionConfig.filter(x => x.id !== v.id)
      this.handleChange()
    },
  },
}
</script>

